import { graphql, navigate } from "gatsby"
import * as React from "react"
import { useState, useEffect, useCallback } from "react"
import { Footer } from "../components/Footer/Footer"
import { Header } from "../components/Header/Header"
import { PortalMultiCostingCalculationModal } from "../components/PortalMultiCostingCalculationModal/PortalMultiCostingCalculationModal"
import {
  ISelectedRequestItem,
  PortalRequestList,
} from "../components/PortalRequestList/PortalRequestList"
import { IToolbarCommand } from "../components/Toolbar/Toolbar"
import { IModalProps, Modal } from "../components/Modal/Modal"
import { Helmet } from "react-helmet"
import * as styles from "./Portal.module.scss"
import * as globalStyles from "../globals.module.scss"
import * as pageStyles from './Page.module.scss'
import { TextField } from "../components/WebBlocks/WebForm/TextField/TextField"
import { DatePickerField } from "../components/WebBlocks/WebForm/DatePickerField/DatePickerField"
import { SiteGlobalConfig } from "../site.config"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react"
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator"
import { Redirect } from "@reach/router"
import { InteractionStatus } from "@azure/msal-browser"

import { TitleBlock } from "../components/WebBlocks/WebBlocks"
import { ParagraphCard } from "../components/WebBlocks/WebBlocks"
import { HeaderImageFromUrl } from "./HeaderImageFromUrl/HeaderImageFromUrl"

export interface IPortalProps {}

export const Portal = (props: IPortalProps) => {
  const [mccModalIsOpen, setMccModalIsOpen] = useState<boolean>(false)
  const [selectedRequests, setSelectedRequests] = useState<
    ISelectedRequestItem[]
  >([])
  const [portalRequestToolbarCommands, setPortalRequestToolbarCommands] =
    useState<IToolbarCommand[]>([])
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<IModalProps>()
  const [confirmationModalFormData, setConfirmationModalFormData] =
    useState<any>({})
  const {
    PRODUCTION_SITE_HOSTNAME,
    WORKFLOW_PUBLIC_RELEASE,
    WORKFLOW_HOLD_REQUEST,
    WORKFLOW_WITHDRAW_REQUEST,
  } = SiteGlobalConfig

  const { instance, accounts, inProgress } = useMsal()

  const handleSelectionChange = (newSelection: ISelectedRequestItem[]) => {
    setSelectedRequests(newSelection)
  }

  // useEffect(() => {
  //     const allAccounts = instance.getAllAccounts();
  //     console.log(accounts);
  //     if(accounts && accounts.length > 0){
  //         instance.setActiveAccount(accounts[0]);
  //     }
  // },[]);

  const handleModalInputChange = (
    workItemId: string,
    key: string,
    value: string
  ) => {
    setConfirmationModalFormData((prevVal: any) => {
      const { [workItemId]: prevItemProps } = prevVal

      return {
        ...prevVal,
        [workItemId]: {
          ...prevItemProps,
          [key]: value,
        },
      }
    })
  }

  const callApiOnModelConfirm = useCallback(
    async (
      apiUrl: string,
      selectedRequests: any[],
      additionalBodyProps: any = {}
    ) => {
      console.log("SUBMITTING", selectedRequests, confirmationModalFormData)
      const responses = await Promise.all(
        selectedRequests.map(async request => {
          const { [request.MasterworkitemID]: formData } =
            confirmationModalFormData
          console.log(formData)

          const res = await fetch(apiUrl, {
            body: JSON.stringify({
              ...formData,
              ...additionalBodyProps,
              masterWorkItemId: request.MasterworkitemID,
              clientRequestsItemId: request.Id,
            }),
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          })

          return res.ok
        })
      )

      setConfirmationModalProps(prev => ({ ...prev, isOpen: false }))

      return responses
    },
    [confirmationModalFormData, selectedRequests]
  )

  useEffect(() => {
    console.log(inProgress)
  }, [inProgress])

  useEffect(() => {
    if (confirmationModalProps && confirmationModalProps.isOpen === false) {
      setConfirmationModalFormData({})
    }
  }, [confirmationModalProps])

  useEffect(() => {
    setPortalRequestToolbarCommands([
      {
        key: "new",
        text: "New",
        onClick: () => null,
        children: [
          {
            key: "newPCR",
            text: "Policy costing request",
            onClick: () => navigate("/secure/policycostingform/new"),
          },
          {
            key: "newPCR",
            text: "Advisory services request",
            onClick: () => navigate("/secure/adviceform/new"),
          },
          {
            key: "mcc",
            text:
              selectedRequests.length <= 0
                ? `Multi-costing calculation`
                : `Multi-costing calculation (${selectedRequests.length} selected)`,
            onClick: () => setMccModalIsOpen(true),
            disabled: selectedRequests.length <= 1,
          },
        ],
      },
      {
        key: "publicRelease",
        // text: selectedRequests.length <= 0 ? `Request public release` : `Request public release (${selectedRequests.length} selected)`,
        text: `Request public release`,
        onClick: () => {
          setConfirmationModalProps(
            getConfirmationModalProps("publicRelease", selectedRequests)
          )
        },
        // Only approved items can be released
        disabled:
          selectedRequests.length <= 0 ||
          (selectedRequests.length > 0 &&
            selectedRequests
              .map(v => v.Currentstate)
              .filter(v => v && v.match(/approved/gi)).length <
              selectedRequests.length),
      },
      {
        key: "update",
        // text: selectedRequests.length <= 0 ? `Request updated response` : `Request updated response (${selectedRequests.length} selected)`,
        text: `Request updated response`,
        onClick: () => {
          setConfirmationModalProps(
            getConfirmationModalProps("update", selectedRequests)
          )
        },
        // Updates can only be requested for approved items //
        disabled:
          selectedRequests.length <= 0 ||
          (selectedRequests.length > 0 &&
            selectedRequests
              .map(v => v.Currentstate)
              .filter(v => v && v.match(/approved/gi)).length <
              selectedRequests.length),
      },
      {
        key: "withdraw",
        // text: selectedRequests.length <= 0 ? `Withdraw` : `Withdraw (${selectedRequests.length} selected)`,
        text: `Withdraw`,
        onClick: () => {
          setConfirmationModalProps(
            getConfirmationModalProps("withdraw", selectedRequests)
          )
        },
        // Only NOT approved items can be withdrawn
        disabled:
          selectedRequests.length <= 0 ||
          (selectedRequests.length > 0 &&
            selectedRequests
              .map(v => v.Currentstate)
              .filter(v => v && v.match(/approved/gi)).length > 0),
      },
      {
        key: "hold",
        // text: selectedRequests.length <= 0 ? `Place on hold` : `Place on hold (${selectedRequests.length} selected)`,
        text: `Place on hold`,
        onClick: () => {
          setConfirmationModalProps(
            getConfirmationModalProps("hold", selectedRequests)
          )
        },
        // Only NOT approved items can be held
        disabled:
          selectedRequests.length <= 0 ||
          (selectedRequests.length > 0 &&
            selectedRequests
              .map(v => v.Currentstate)
              .filter(v => v && v.match(/approved/gi)).length > 0),
      },
    ])
  }, [selectedRequests])

  const getConfirmationModalProps: (
    modalKey: "mcc" | "update" | "withdraw" | "hold" | "publicRelease",
    selectedRequests: ISelectedRequestItem[]
  ) => IModalProps = (modalKey, selectedRequests) => {
    console.log(modalKey, selectedRequests)
    switch (modalKey) {
      case "publicRelease": {
        return {
          isOpen: true,
          headerText: "Are you sure",
          bodyText: `Are you sure you would like to release the ${
            selectedRequests.length
          } selected ${
            selectedRequests.length > 1 ? `responses` : `response`
          } to the public via pbo.vic.gov.au?`,
          // Add fields for release date and general comment
          onRenderBody: (bodyText: string) => {
            return (
              <div>
                <p>{bodyText}</p>
                <br />
                <div></div>
                <table style={{ width: "100%", rowGap: "0.8em" }}>
                  {selectedRequests.map(req => {
                    const now = new Date()
                    return (
                      <tbody>
                        <tr>
                          <span>&nbsp;</span>
                        </tr>
                        <tr>
                          <th
                            style={{
                              padding: "0.5em",
                              backgroundColor: "#666",
                              color: "white",
                              marginTop: "1em",
                              borderBlock: "1px solid grey",
                            }}
                          >
                            {req.Title}
                          </th>
                        </tr>
                        <tr>
                          <td>
                            <DatePickerField
                              Label="Public release date (leave blank for as soon as practicable)"
                              onChange={newVal =>
                                handleModalInputChange(
                                  req.MasterworkitemID,
                                  "publicReleaseDate",
                                  newVal
                                )
                              }
                              Value={
                                confirmationModalFormData &&
                                confirmationModalFormData.publicReleaseDate
                              }
                              formValues={{}}
                              id="publicReleaseDate"
                            />
                            <TextField
                              Label="Notes"
                              Multiline={true}
                              onChange={newVal =>
                                handleModalInputChange(
                                  req.MasterworkitemID,
                                  "notes",
                                  newVal
                                )
                              }
                              Value={
                                confirmationModalFormData &&
                                confirmationModalFormData.notes
                              }
                              formValues={{}}
                              id="notes"
                            />
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
                </table>
                <br />
              </div>
            )
          },
          cancelButtonText: "No, cancel",
          confirmButtonText: "Yes please",
          onDismiss: () =>
            setConfirmationModalProps(prev => ({ ...prev, isOpen: false })),
          onConfirm: () =>
            callApiOnModelConfirm(WORKFLOW_PUBLIC_RELEASE, selectedRequests),
        }
      }
      case "update": {
        return {
          isOpen: true,
          headerText: "Are you sure",
          bodyText: `Are you sure you would like to submit a request to update the ${
            selectedRequests.length
          } selected ${
            selectedRequests.length > 1 ? `responses` : `response`
          } against the latest budget or budget update?`,
          onRenderBody: bodyText => {
            return (
              <div>
                <p>{bodyText}</p>
                <br />
                <table style={{ width: "100%" }}>
                  {selectedRequests.map(req => {
                    const now = new Date()
                    return (
                      <tbody>
                        <tr>
                          <span>&nbsp;</span>
                        </tr>
                        <tr>
                          <th
                            style={{
                              padding: "0.5em",
                              backgroundColor: "white",
                              marginTop: "1em",
                              borderBlock: "1px solid grey",
                            }}
                          >
                            {req.Title}
                          </th>
                        </tr>
                        <tr>
                          <td
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1em",
                            }}
                          >
                            <TextField
                              onChange={() => null}
                              Label="New request name"
                              formValues={{}}
                              id="notes"
                              Value={`${req.Title} (${now.toLocaleDateString(
                                "en-au",
                                { month: "long", year: "numeric" }
                              )})`}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "1em",
                              }}
                            >
                              <div style={{ flexGrow: 1 }}>
                                <DatePickerField
                                  onChange={() => null}
                                  Label="Policy implementation date"
                                  formValues={{}}
                                  id="implementationDate"
                                  Value={null}
                                />
                              </div>
                              <div style={{ flexGrow: 1 }}>
                                <DatePickerField
                                  onChange={() => null}
                                  Label="Critical response date (optional)"
                                  formValues={{}}
                                  id="CRD"
                                  Value={null}
                                />
                              </div>
                            </div>
                            <TextField
                              onChange={() => null}
                              Label="Notes"
                              Multiline={true}
                              formValues={{}}
                              id="notes"
                              Value=""
                            />
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
                </table>
                <br />
              </div>
            )
          },
          // Add fields for Name, intended implementation date, CRD
          cancelButtonText: "No, cancel",
          confirmButtonText: "Yes please",
          onDismiss: () =>
            setConfirmationModalProps(prev => ({ ...prev, isOpen: false })),
          onConfirm: () => null,
        }
      }
      case "withdraw": {
        return {
          isOpen: true,
          headerText: "Are you sure",
          bodyText: `Are you sure you would like to withdraw the ${
            selectedRequests.length
          } selected ${
            selectedRequests.length > 1 ? `requests` : `request`
          }?\nThis means the PBO will stop preparing this reponse.`,
          // add field for withdrawal comment
          cancelButtonText: "No, cancel",
          confirmButtonText: "Yes",
          onDismiss: () =>
            setConfirmationModalProps(prev => ({ ...prev, isOpen: false })),
          onConfirm: () =>
            callApiOnModelConfirm(WORKFLOW_WITHDRAW_REQUEST, selectedRequests),
        }
      }
      case "hold": {
        return {
          isOpen: true,
          headerText: "Are you sure",
          bodyText: `Are you sure you would like to place the ${
            selectedRequests.length
          } selected ${
            selectedRequests.length > 1 ? `requests` : `request`
          } on hold?`,
          // add field for hold review date, hold comment
          cancelButtonText: "No, cancel",
          confirmButtonText: "Yes",
          onDismiss: () =>
            setConfirmationModalProps(prev => ({ ...prev, isOpen: false })),
          onConfirm: () =>
            callApiOnModelConfirm(WORKFLOW_HOLD_REQUEST, selectedRequests),
        }
      }
      default: {
        return null
      }
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Victorian PBO client portal</title>
        <link
          rel="canonical"
          href={`https://${PRODUCTION_SITE_HOSTNAME}/secure/portal`}
        />
      </Helmet>
      <AuthenticatedTemplate>
        {/* START NEW SECTION */}
        <div className={pageStyles.pageHeaderImage} >
        <div
        // hardcoding these in.
        //to-do - dynamic like regular Page template
          className={[
            pageStyles.imageContainer,
            pageStyles.imageContainerSmall,
            pageStyles.headerImageRight,
            styles.portalHeaderImage
          ].join(" ")}
        >
          <div className={pageStyles.imageHeightContainer}>
            <HeaderImageFromUrl
              imageSrc="https://pbowebstorage.blob.core.windows.net/images/Parliament/queenvictoria.jpg"
              imageAlt="Parliament House"
            />
          </div>
        </div>
        </div>
        <section
          className={[globalStyles.sectionGrid, "page-section-grid", styles.portalIntroSection].join(" ")}
        >
          <TitleBlock extraClassNames={styles.portalH2} Primary_x0020_text="Parliamentary Budget Office client service centre" style={{ gridColumn: '3 / 10', fontSize: '1.3em', lineHeight: '1.3em', marginTop: '2.2em'}} />
          <ParagraphCard style={{ gridColumn: '3 / 11'}} 
            Secondary_x0020_text={`<p>Welcome to the Parliamentary Budget Office client service centre.</p>
<p>For information about the services we provide, <a style="position: relative;" href=https://pbo.vic.gov.au/services target="_blank">click here</a>. To submit a request for a new policy costing or advisory service, click the 'New' button below.</p>
<p>If you have any questions or need assistance, please contact us at <a style="position: relative;" href=mailto:enquiries@pbo.vic.gov.au>enquiries@pbo.vic.gov.au</a> or on 1300 615 862 during business hours.</p>`}
          />

          {/* END NEW SECTION */}
          <PortalRequestList
            onSelectionChange={handleSelectionChange}
            toolbarCommands={portalRequestToolbarCommands}
            openModalByKey={(key: string, selected: ISelectedRequestItem[]) =>
              setConfirmationModalProps(
                getConfirmationModalProps(key, selected)
              )
            }
          />
        </section>
        <PortalMultiCostingCalculationModal
          isOpen={mccModalIsOpen}
          onDismiss={() => setMccModalIsOpen(false)}
          selectedRows={selectedRequests}
        />
        <Modal {...confirmationModalProps} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {inProgress !== InteractionStatus.None ? (
          <div>
            <LoadingIndicator />
            <span>Checking authentication status...</span>
          </div>
        ) : (
          // <Redirect to="/secure/login" />
          <span>{inProgress}</span>
        )}
      </UnauthenticatedTemplate>
    </>
  )
}
