import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import OverlayClip from '../../assets/heroOverlayClip.asset.svg';
import { IContentItemQueryResult } from '../../interfaces/IContentItemQueryResult';
import { EHeaderImageAlignment, IPageItemQueryResult } from '../../interfaces/IPageItemQueryResult';

import * as styles from './HeaderImageFromUrl.module.scss';

export interface IHeaderImageFromUrlProps {
    imageSrc: string;
    imageAlt: string;
    imageAlignment?: EHeaderImageAlignment;
}

export const HeaderImageFromUrl = (props: IHeaderImageFromUrlProps) => {
    const { imageAlignment, imageAlt, imageSrc } = props;

    return(
        <div className={ styles.pageHeaderImage }>
            <div className={ [styles.imageContainer, (imageAlignment === EHeaderImageAlignment.Left ? styles.headerImageLeft : styles.headerImageRight)].join(" ") }>
                {/* <div className={ styles.imageHeightContainer }> */}
                    <div className={ styles.headerImage }>
                        <img src={ imageSrc } alt={ imageAlt } />
                    </div>
                    <div className={ styles.logo }></div>
                {/* </div> */}
                <OverlayClip />
            </div>
        </div>
    )
}